import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export default function useNavigateBack(defaultPath = '/search') {
  const navigate = useNavigate()
  const location = useLocation()
  const hasPrev = location.state?.hasPrev ?? false

  return useCallback(() => {
    if (hasPrev) {
      navigate(-1)
    } else {
      navigate(defaultPath, { state: { hasPrev: true } })
    }
  }, [defaultPath, hasPrev, navigate])
}

import { Button, Group, Image, Modal } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useCallback, useState } from 'react'

import trackEvent from 'queries/event'
import useDisplayStore from 'states/useDisplayStore'
import { globalFunc, globalVar } from 'utils/utils'
import MessageModal from './MessageModal'

type Props = {
  title: string
  message: string
  askOnlyOnce?: boolean
  onFinish?: () => void
}

function NotificationsPermissionModal({
  title,
  message,
  askOnlyOnce = false,
  onFinish = undefined,
}: Props) {
  const {
    requestedNotificationsPermission,
    updateRequestedNotificationsPermission,
  } = useDisplayStore((state) => ({
    requestedNotificationsPermission: state.requestedNotificationsPermission,
    updateRequestedNotificationsPermission:
      state.updateRequestedNotificationsPermission,
  }))
  const [opened, { open, close }] = useDisclosure(true)
  const [askedAndRefused, setAskedAndRefused] = useState(false)
  const onClose = onFinish || close

  const handleRefuse = useCallback(() => {
    trackEvent('permissions_refused', {})
    updateRequestedNotificationsPermission(true)
    onClose()
  }, [onClose])

  const handleAccept = useCallback(() => {
    trackEvent('permissions_asking', {})
    const callback = ({
      status,
      token,
    }: {
      status: string
      token: string | null
    }) => {
      window.notifPermission = status
      updateRequestedNotificationsPermission(true)
      if (status === 'granted' && token) {
        trackEvent('permissions_accepted', {})
        window.expoPushToken = token
        if (onFinish) {
          onFinish()
        }
      } else {
        setAskedAndRefused(true)
        open()
        trackEvent('permissions_refused_after_ask', {})
      }
    }
    globalFunc('askNotifPermission', callback)
    if (!onFinish) {
      close()
    }
  }, [close, onFinish])

  if (!globalVar('notifPermission') || !globalVar('askNotifPermission')) {
    return null
  }

  if (askedAndRefused) {
    return (
      <MessageModal>
        Pour recevoir les notifications, activez les autorisations dans les
        paramètres de votre téléphone :
        <br /> Applications &#x3009; Bobine &#x3009; Notifications.
      </MessageModal>
    )
  }

  if (
    (askOnlyOnce && requestedNotificationsPermission) ||
    globalVar('notifPermission') === 'granted'
  ) {
    // No need to ask again
    return null
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      centered
      className="bg-neutral-900"
      classNames={{
        content: 'bg-neutral-900 text-white text-center',
      }}
      style={{
        fontFamily: 'Josefin Sans, sans-serif',
      }}
      closeOnEscape={false}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          event.stopPropagation()
          onClose()
        }
      }}
    >
      <div className="font-semibold text-xl mb-4">{title}</div>
      <Image src="/notification.jpg" className="mb-4 max-h-[40vh]" />
      {message}
      <Group justify="space-between" className="mx-auto px-5 mt-8 w-full">
        <Button
          size="md"
          color="zinc"
          className="border-zinc-600 focus:outline-none"
          style={{
            fontFamily: 'Josefin Sans, sans-serif',
          }}
          onClick={handleRefuse}
        >
          Non merci
        </Button>
        <Button
          size="md"
          color="zinc.6"
          variant="white"
          className="border-white"
          style={{
            fontFamily: 'Josefin Sans, sans-serif',
          }}
          onClick={handleAccept}
        >
          Activer
        </Button>
      </Group>
    </Modal>
  )
}

export default NotificationsPermissionModal

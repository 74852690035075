import { ActionIcon, Center, ScrollArea, Tabs, Text } from '@mantine/core'
import ShowtimeModal from 'components/showtimemodal/ShowtimeModal'
import dayjs from 'dayjs'
import { useCurrentShowtimes } from 'providers/currentShowtimesProvider'
import {
  type ReactNode,
  createRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import useDisplayStore from 'states/useDisplayStore'
import { ChevronLeft, ChevronRight } from 'tabler-icons-react'
import type { MovieWithShowtimes } from 'types/showtimes'
import NoShowtimes from './NoShowtimes'

function checkDateHasShowtimes(
  movies: MovieWithShowtimes[] | undefined,
  date: dayjs.Dayjs
): boolean {
  return (
    movies?.some((resp: MovieWithShowtimes) =>
      resp.theaters.some(({ showtimes }) =>
        showtimes.some(({ showtime }) => showtime.isSame(date.utc(), 'day'))
      )
    ) ?? false
  )
}

function getFutureDates() {
  let startDay = dayjs()
  startDay = dayjs.utc(startDay.format('YYYY-MM-DD'), 'YYYY-MM-DD').locale('fr')
  return Array.from({ length: 7 }, (_, i) => startDay.add(i, 'days'))
}

const chevronStyle =
  'border bg-white border-zinc-300 !h-full text-zinc-600 disabled:opacity-30 disabled:bg-zinc-200 disabled:border-none'
const dates = getFutureDates()

type Props = {
  children?: ReactNode
}

function ShowtimesTab({ children = undefined }: Props) {
  const scrollViewport = useRef<HTMLDivElement>(null)
  const { matchingShowtimes, allShowtimes } = useCurrentShowtimes()

  const dateRefs = useMemo(
    () =>
      Object.fromEntries(
        dates.map((date) => [date.toString(), createRef<HTMLButtonElement>()])
      ),
    []
  )

  const firstDate = useMemo(() => {
    const relevantShowtimes =
      matchingShowtimes.length > 0 && matchingShowtimes[0].theaters.length > 0
        ? matchingShowtimes
        : allShowtimes
    return dates?.find((date) => checkDateHasShowtimes(relevantShowtimes, date))
  }, [matchingShowtimes, allShowtimes])
  const selectedDate = useDisplayStore((state) => state.currentDate)
  const setSelectedDate = useDisplayStore((state) => state.updateCurrentDate)
  const [scrollableLeft, setScrollableLeft] = useState(false)
  const [scrollableRight, setScrollableRight] = useState(true)

  const scrollFromButton = useCallback((goLeft: boolean) => {
    if (scrollViewport.current == null) {
      return
    }
    const { offsetWidth: viewportWidth, scrollWidth } = scrollViewport.current
    const len = dates.length || 1
    const avg = scrollWidth / len
    if (viewportWidth) {
      const offset = Math.ceil(viewportWidth - avg)
      scrollViewport.current?.scrollBy({
        left: goLeft ? offset : -offset,
        behavior: 'smooth',
      })
    }
  }, [])

  const scrollToDate = useCallback(
    (date: string | undefined | null, smooth: boolean) => {
      if (!date) {
        return
      }
      const dateEl = dateRefs[date].current
      if (dateEl) {
        dateEl.scrollIntoView({
          behavior: smooth ? 'smooth' : 'auto',
          block: 'nearest',
          inline: 'center',
        })
      }
    },
    [dateRefs]
  )

  useEffect(() => {
    const scrollEl = scrollViewport.current

    function updateScrollable() {
      if (scrollEl == null) {
        return
      }

      const { scrollLeft, scrollWidth, clientWidth } = scrollEl

      setScrollableLeft(scrollLeft !== 0)
      setScrollableRight(Math.abs(scrollLeft + clientWidth - scrollWidth) > 1) // > 1, for mobile (devicePixel=2)
    }

    function stopPropagation(event: Event) {
      // prevent from swiping tabs in mobile version.
      event?.stopPropagation()
    }

    updateScrollable()
    if (scrollEl) {
      scrollEl.addEventListener('scroll', updateScrollable)
      scrollEl.addEventListener('touchmove', stopPropagation)
    }

    return () => {
      if (scrollEl) {
        scrollEl.removeEventListener('scroll', updateScrollable)
        scrollEl.removeEventListener('touchmove', stopPropagation)
      }
    }
  }, [])

  useEffect(() => {
    if (firstDate) {
      setSelectedDate(firstDate)
      scrollToDate(firstDate.toString(), false)
    }
  }, [firstDate, setSelectedDate, scrollToDate])

  return (
    <div className="h-full overflow-hidden">
      <ShowtimeModal />
      <Tabs
        value={selectedDate?.toString()}
        className="flex flex-col h-full overflow-hidden"
        onChange={(date) => {
          const str = date?.toString()
          scrollToDate(str, true)
          setSelectedDate(dayjs(str))
        }}
        unstyled
        classNames={{
          tab: 'px-4 py-2 mt-2 border-2 border-white cursor-pointer text-sm align-middle disabled:opacity-30 disabled:cursor-not-allowed data-[active]:border-b-zinc-600',
        }}
      >
        <div className="w-full flex align-middle justify-center">
          <Center>
            <ActionIcon
              className={chevronStyle}
              onClick={() => scrollFromButton(false)}
              disabled={!scrollableLeft}
            >
              <ChevronLeft />
            </ActionIcon>
          </Center>
          <ScrollArea
            className="w-full overflow-hidden bg-white border-b border-zinc-300"
            viewportRef={scrollViewport}
            type="never"
          >
            <Tabs.List className="w-full flex whitespace-nowrap">
              {dates?.map((date) => {
                const id = date.toString()
                const day = date.format('dddd')
                const short = date.format('D MMM')
                return (
                  <Tabs.Tab
                    key={id}
                    value={id}
                    ref={dateRefs[id]}
                    disabled={!checkDateHasShowtimes(allShowtimes, date)}
                  >
                    <Text size="sm" fw={400}>
                      {day}
                    </Text>
                    <Text size="sm" fw={400}>
                      {short}
                    </Text>
                  </Tabs.Tab>
                )
              })}
            </Tabs.List>
          </ScrollArea>
          <Center>
            <ActionIcon
              className={chevronStyle}
              onClick={() => scrollFromButton(true)}
              disabled={!scrollableRight}
            >
              <ChevronRight />
            </ActionIcon>
          </Center>
        </div>

        {firstDate && children}

        {firstDate === undefined && (
          <div className="flex justify-center items-center mt-6">
            <NoShowtimes />
          </div>
        )}
      </Tabs>
    </div>
  )
}

export default memo(ShowtimesTab)

import type dayjs from 'dayjs'
import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { useShallow } from 'zustand/shallow'

interface IState {
  currentDate: dayjs.Dayjs | undefined
  requestedAppReview: boolean
  requestedInstallOrUpgrade: boolean
  requestedNotificationsPermission: boolean
  nbLogins: number
  watchlistMovieIds?: number[]
  updateCurrentDate: (date: dayjs.Dayjs | undefined) => void
  updateRequestedAppReview: (requested: boolean) => void
  updateRequestedInstallOrUpgrade: (requested: boolean) => void
  updateRequestedNotificationsPermission: (requested: boolean) => void
  setNbLogins: (nbLogins: number) => void
  setWatchlistMovieIds: (watchlistMovieIds: number[]) => void
}

const displayStore = create<IState>()(
  devtools(
    persist(
      (set) => ({
        currentDate: undefined,
        requestedAppReview: false,
        requestedInstallOrUpgrade: false,
        requestedNotificationsPermission: false,
        nbLogins: 0,
        watchlistMovieIds: [],
        updateCurrentDate: (currentDate: dayjs.Dayjs | undefined) =>
          set({ currentDate }),
        updateRequestedAppReview: (requestedAppReview: boolean) =>
          set({ requestedAppReview }),
        updateRequestedInstallOrUpgrade: (requestedInstallOrUpgrade: boolean) =>
          set({ requestedInstallOrUpgrade }),
        updateRequestedNotificationsPermission: (
          requestedNotificationsPermission: boolean
        ) => set({ requestedNotificationsPermission }),
        setNbLogins: (nbLogins: number) => set({ nbLogins }),
        setWatchlistMovieIds: (watchlistMovieIds: number[]) =>
          set({ watchlistMovieIds }),
      }),
      {
        name: 'display',
        storage: createJSONStorage(() => localStorage),
        onRehydrateStorage: () => (state) => {
          if (state) {
            // Increment the number of logins locally
            setTimeout(() => {
              state.setNbLogins(state.nbLogins + 1)
            }, 0)
          }
        },
      }
    )
  )
)

function useDisplayStore<T>(selector: (state: IState) => T): T {
  return displayStore(useShallow(selector))
}

export default useDisplayStore

import { ActionIcon, Container, Tooltip } from '@mantine/core'
import { memo, useCallback, useState } from 'react'
import { TbBell, TbBellRingingFilled } from 'react-icons/tb'

import NotificationsPermissionModal from 'components/common/NotificationsPermissionModal'
import { useCurrentMovie } from 'providers/currentMovieProvider'
import { useIsMobile } from 'providers/isMobileProvider'
import trackEvent from 'queries/event'
import { updateWatchlist } from 'queries/users'
import useDisplayStore from 'states/useDisplayStore'
import { globalVar } from 'utils/utils'

type Props = {
  size?: number
  textColor?: string
  children?: (isInWatchlist: boolean) => React.ReactNode
}

function WatchlistBell({
  size = 28,
  textColor = 'text-zinc-100',
  children = undefined,
}: Props) {
  const { isMobileApp } = useIsMobile()
  const { movie } = useCurrentMovie()
  const { watchlistMovieIds, setWatchlistMovieIds } = useDisplayStore(
    (state) => ({
      watchlistMovieIds: state.watchlistMovieIds,
      setWatchlistMovieIds: state.setWatchlistMovieIds,
    })
  )
  const isInWatchlist = watchlistMovieIds?.includes(movie.id) ?? false
  const [permissionModalOpened, setPermissionModalOpened] = useState(false)

  const handleClick = useCallback(async () => {
    trackEvent('watchlist', {
      id: movie.id,
      movieName: movie.titleVf,
      isInWatchlist: !isInWatchlist,
    })
    const expoToken = globalVar<string>('expoPushToken')
    if (expoToken) {
      await updateWatchlist(expoToken, movie.id, isInWatchlist)
      setWatchlistMovieIds(
        isInWatchlist
          ? (watchlistMovieIds?.filter((id) => id !== movie.id) ?? [])
          : [...(watchlistMovieIds ?? []), movie.id]
      )
    }
  }, [movie, isInWatchlist, watchlistMovieIds, setWatchlistMovieIds])

  const onClick = useCallback(() => {
    if (globalVar<string>('expoPushToken')) {
      handleClick()
    } else {
      setPermissionModalOpened(true)
    }
  }, [handleClick])

  if (!isMobileApp) {
    return null
  }

  return (
    <Tooltip
      label="Vous recevrez une notification quand des séances seront disponibles."
      disabled={!isInWatchlist}
      multiline
      withArrow
      position="bottom"
      events={{ hover: true, focus: true, touch: true }}
      className="max-w-32 text-center"
    >
      <div className="w-fit">
        {permissionModalOpened && (
          <NotificationsPermissionModal
            title="Activer les alertes"
            message={`Recevez une notification quand des séances sont disponibles pour ${movie.titleVf}.`}
            onFinish={() => {
              if (globalVar<string>('expoPushToken')) {
                handleClick()
              }
              setPermissionModalOpened(false)
            }}
          />
        )}
        {children ? (
          <Container onClick={onClick}>{children(isInWatchlist)}</Container>
        ) : (
          <ActionIcon
            variant="transparent"
            size={size}
            onClick={onClick}
            className={`transition ease-in translate-y-[0.25rem] ${isInWatchlist ? 'text-sky-500' : textColor}`}
          >
            {isInWatchlist ? (
              <TbBellRingingFilled size={size} />
            ) : (
              <TbBell size={size} />
            )}
          </ActionIcon>
        )}
      </div>
    </Tooltip>
  )
}

export default memo(WatchlistBell)

import { BackgroundImage, Image, ScrollArea, Space, Stack } from '@mantine/core'
import { memo } from 'react'

import { useCurrentMovie } from 'providers/currentMovieProvider'
import { useIsMobile } from 'providers/isMobileProvider'
import { formatDuration, formatReleaseDate } from 'utils/utils'
import MobileModalHeader from '../genericmodal/MobileModalHeader'
import WatchlistBell from './WatchlistBell'

function isDefined(a: string | null | undefined) {
  return a != null && a.length > 0
}

function MovieModalContent() {
  const { isMobile } = useIsMobile()
  const { movie } = useCurrentMovie()

  const layoutClasses = 'w-full max-w-7xl mx-auto'

  const infoLabel = [
    movie.countries,
    formatReleaseDate(movie.releaseDate),
    formatDuration(movie.duration),
  ]
    .filter(isDefined)
    .join(' · ')

  return (
    <ScrollArea
      style={{
        fontFamily: 'Josefin Sans, sans-serif',
      }}
    >
      <BackgroundImage
        src={movie.stillPath || movie.posterHDPath}
        className={`relative text-white ${
          isMobile ? 'h-[calc(var(--page-height)-16rem)]' : 'h-[47vh]'
        }`}
        style={{ backgroundPosition: 'center 35%' }}
      >
        <div className="w-full bg-gradient-to-b from-[rgba(28,28,28,0.8)] to-transparent">
          <div className={`flex p-[10px] ${layoutClasses}`}>
            {!isMobile && (
              <div className="w-[120px] mr-4">
                <Image
                  src={movie.posterPath}
                  fit="cover"
                  radius="md"
                  alt={`Affiche du film ${movie.titleVf}`}
                />
              </div>
            )}
            <Stack gap={1} className={layoutClasses}>
              <MobileModalHeader className="mb-2">
                <WatchlistBell />
              </MobileModalHeader>
              <h1 className="font-semibold text-2xl uppercase">
                {movie.titleVf}
              </h1>
              {movie.titleVo.toLowerCase() !== movie.titleVf.toLowerCase() && (
                <div className="text-base font-light uppercase italic">
                  {movie.titleVo}
                </div>
              )}
              {movie.director && (
                <div className="text-sm mt-1 font-light">
                  Réalisé par{' '}
                  <span className="font-medium">{movie.director}</span>
                </div>
              )}
              {movie.casting && (
                <div className="text-sm mt-1 font-light">
                  Avec <span className="font-medium">{movie.casting}</span>
                </div>
              )}
              <Space h={70} />
            </Stack>
          </div>
        </div>
        <div className="absolute w-full bg-gradient-to-t from-[rgba(28,28,28,0.8)] to-transparent bottom-0">
          <Stack
            gap={1}
            className={`flex p-[10px] ${layoutClasses}`}
            style={{
              fontFamily: 'Josefin Sans, sans-serif',
            }}
          >
            <Space h={70} />
            {infoLabel && (
              <div className="text-base font-light mt-2">{infoLabel}</div>
            )}
            {movie.genres && (
              <div className="mt-1 font-light italic text-grey">
                {movie.genres}
              </div>
            )}
          </Stack>
        </div>
      </BackgroundImage>
    </ScrollArea>
  )
}

export default memo(MovieModalContent)

import { Button, Stack, Text } from '@mantine/core'
import { TbBell, TbBellRingingFilled } from 'react-icons/tb'

import AndroidBadge from 'components/common/AndroidBadge'
import AppleBadge from 'components/common/AppleBadge'
import { useCurrentShowtimes } from 'providers/currentShowtimesProvider'
import { useIsMobile } from 'providers/isMobileProvider'
import WatchlistBell from './WatchlistBell'

export default function NoShowtimes() {
  const { pageType } = useCurrentShowtimes()
  const { isMobileApp } = useIsMobile()

  if (pageType === 'theater') {
    return <Text className="italic">Aucune séance prévue.</Text>
  }

  if (isMobileApp) {
    return (
      <Stack className="flex flex-col items-center mx-4">
        <Text className="italic">Aucune séance prévue.</Text>
        <WatchlistBell>
          {(isInWatchlist) => (
            <Button
              leftSection={
                isInWatchlist ? (
                  <TbBellRingingFilled size={24} />
                ) : (
                  <TbBell size={24} />
                )
              }
            >
              Être notifié {isInWatchlist ? '✓' : ''}
            </Button>
          )}
        </WatchlistBell>
      </Stack>
    )
  }

  return (
    <Stack className="flex flex-col items-center mx-4">
      <Text className="italic">Aucune séance prévue.</Text>
      <Text className="text-center mb-2">
        Soyez notifié des prochaines séances avec l&apos;application mobile :
      </Text>
      <AppleBadge scale={1.1} />
      <AndroidBadge scale={1.2} />
    </Stack>
  )
}
